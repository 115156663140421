const syllabus = [
  {
    id: 1,
    name: "Class Nursery",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/YEARLY%20SYLLABUS%20NURSERY.docx",
  },
  {
    id: 2,
    name: "Class Prep",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/YEARLY%20SYLLABUS%20PREP.docx",
  },
  {
    id: 1,
    name: "Class I Syllabus",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-I-syllabus-Oct-Dec-2022-23.pdf"
  },

  {
    id: 1,
    name: "Class I Worksheet & Syllabus - Nov",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-I-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  },

  {
    id: 2,
    name: "Class II Syllabus",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-II-syllabus-Oct-Dec-2022-23.pdf"
  },
  {
    id: 1,
    name: "Class II Worksheet & Syllabus - Nov",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-II-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  },
  {
    id: 3,
    name: "Class III Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-III-syllabus-Oct-Dec-2022-23.pdf",
  },
  {
    id: 1,
    name: "Class III Worksheet & Syllabus - Nov",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  },
  {
    id: 1,
    name: "Class III - Periodic I Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-PERIODIC-I.pdf",
  },

  {
    id: 1,
    name: "Class III - Periodic II Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-III-(2022-23).pdf",
  },

  {
    id: 1,
    name: "Class III - Half Yearly Examination Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-HFY-SYLLABUS-(2022-23).pdf",
  },

  {

    id: 4,
    name: "Class IV Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-IV-syllabus-Oct-Dec-2022-23.pdf",
  },


  {
    id: 1,
    name: "Class IV Worksheet & Syllabus - Nov",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  },


  {
    id: 1,
    name: "Class IV - Periodic I Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-PERIODIC-I.pdf",
  },

  {
    id: 1,
    name: "Class IV - Periodic II Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-IV(2022-23).pdf",
  },

  {
    id: 1,
    name: "Class IV - Half Yearly Examination Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-HFY-SYLLABUS-(2022-23).pdf",
  },

  {
    id: 5,
    name: "Class V Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-V-syllabus-Oct-Dec-2022-23.pdf",

  },
  {
    id: 1,
    name: "Class V Worksheet & Syllabus - Nov",
    subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-V-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  },

  {
    id: 1,
    name: "Class V - Periodic I Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-V-PERIODIC-I.pdf",
  },

  {
    id: 1,
    name: "Class V - Periodic II Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-V-(2022-23).pdf",
  },


  {
    id: 1,
    name: "Class V - Half Yearly Examination Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-V-HFY-SYLLABUS-(2022-23).pdf",
  },
  {
    id: 6,
    name: "Class VI Syllabus",

    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-6-SYLLABUS-2022-23.pdf",
  },
  {
    id: 7,
    name: "Class VII Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-7-SYLLABUS-2022-23.pdf",
  },
  {
    id: 8,
    name: "Class VIII Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-8-SYLLABUS-2022-23.pdf",
  },
  {
    id: 9,
    name: "Class IX Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-9-SYLLABUS-2022-23.pdf",
  },
  {
    id: 10,
    name: "Class X Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-10-SYLLABUS-2022-23.pdf",
  },
  {
    id: 11,
    name: "Class XI Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2021/CLASSXI.pdf",
  },
  {
    id: 12,
    name: "Class XII Syllabus",

    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-XII.pdf",
  },
];

export default syllabus;
